/* eslint-disable react/jsx-filename-extension */
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Loading from './Loading';

import './index.css';

const Andwin = React.lazy(() => import('./sites/andwin/App'));
const Azer = React.lazy(() => import('./sites/azer/App'));
const BSI = React.lazy(() => import('./sites/bsi/App'));
const BlueRidge = React.lazy(() => import('./sites/blueridge/App'));
const Cleanroom = React.lazy(() => import('./sites/cleanroom/App'));
const DAI = React.lazy(() => import('./sites/dai/App'));
const Daigger = React.lazy(() => import('./sites/daigger/App'));
const Define = React.lazy(() => import('./sites/define/App'));
const DSI = React.lazy(() => import('./sites/dsi/App'));
const Ecom = React.lazy(() => import('./sites/ecom/App'));
const EcomTMP = React.lazy(() => import('./sites/ecom2/App'));
const Farnell = React.lazy(() => import('./sites/farnell/App'));
const Fisher = React.lazy(() => import('./sites/fisher/App'));
const FisherEd = React.lazy(() => import('./sites/fishered/App'));
const Genesee = React.lazy(() => import('./sites/genesee/App'));
const GSS = React.lazy(() => import('./sites/gss/App'));
const GMI = React.lazy(() => import('./sites/gmi/App'));
const H2I = React.lazy(() => import('./sites/h2i/App'));
const Herr = React.lazy(() => import('./sites/herr/App'));
const Kewaunee = React.lazy(() => import('./sites/kewaunee/App'));
const Kloeppel = React.lazy(() => import('./sites/kloeppel/App'));
const LabDepot = React.lazy(() => import('./sites/labdepot/App'));
const LabRepCo = React.lazy(() => import('./sites/labrepco/App'));
const LBI = React.lazy(() => import('./sites/lbi/App'));
const LOC = React.lazy(() => import('./sites/loc/App'));
const LPS = React.lazy(() => import('./sites/lps/App'));
const MGLab = React.lazy(() => import('./sites/mglabsolutions/App'));
const Midland = React.lazy(() => import('./sites/midland/App'));
const Nelson = React.lazy(() => import('./sites/nelson/App'));
const Nova = React.lazy(() => import('./sites/nova/App'));
const Nowak = React.lazy(() => import('./sites/nowak/App'));
const Nycom = React.lazy(() => import('./sites/nycom/App'));
const Nurnberg = React.lazy(() => import('./sites/nurnberg/App'));
const OfficePlanners = React.lazy(() => import('./sites/officeplanners/App'));
const OnePointe = React.lazy(() => import('./sites/onepointe/App'));
const Proline = React.lazy(() => import('./sites/proline/App'));
const QALine = React.lazy(() => import('./sites/qaline/App'));
const Scott = React.lazy(() => import('./sites/scott/App'));
const Spectrum = React.lazy(() => import('./sites/spectrum/App'));
const SpectrumRX = React.lazy(() => import('./sites/spectrumrx/App'));
const Stellar = React.lazy(() => import('./sites/stellar/App'));
const SRS = React.lazy(() => import('./sites/srs/App'));
const SCI = React.lazy(() => import('./sites/sci/App'));
const Superior = React.lazy(() => import('./sites/superior/App'));
const Taylor = React.lazy(() => import('./sites/taylor/App'));
const Thermal = React.lazy(() => import('./sites/thermal/App'));
const Thomas = React.lazy(() => import('./sites/thomas/App'));
const Technimark = React.lazy(() => import('./sites/technimark/App'));
const Omnilab = React.lazy(() => import('./sites/omnilab/App'));
const Phillocraft = React.lazy(() => import('./sites/phillocraft/App'));
const Biologix = React.lazy(() => import('./sites/biologix/App'));
const Labpro = React.lazy(() => import('./sites/labpro/App'));
const Emerald = React.lazy(() => import('./sites/emerald/App'));
const ICI = React.lazy(() => import('./sites/ici/App'));
const Test = React.lazy(() => import('./sites/test/App'));
const EcomIframe = React.lazy(() => import('./sites/ecom iframe/App'));
const EcomSubdomain = React.lazy(() => import('./sites/ecom2/App'));
const Standard = React.lazy(() => import('./sites/standard/App'));

const { hostname, ancestorOrigins } = window.location;
let app = <EcomIframe />;

if (hostname === 'catalog.localhost') {
  app = <Ecom />;
} else if (hostname === 'testing.ecomseating.com') {
  <Test />;
} else if (hostname === 'lablanding.netlify.app') {
  app = <EcomTMP />;
} else if (hostname === 'testing.labtechseating.com') {
  app = <Ecom />;
} else if (hostname === 'seating.bsilab.com') {
  app = <BSI />;
} else if (hostname === 'seating.gmi-inc.com') {
  app = <GMI />;
} else if (hostname === 'seating.nurnberg.com') {
  app = <Nurnberg />;
} else if (hostname === 'chairs.superiorstoragesystems.com') {
  app = <Superior />;
} else if (hostname === 'chairs.cleanroomsupplies.com') {
  app = <Cleanroom />;
} else if (hostname === 'seating.labrepco.com') {
  app = <LabRepCo />;
} else if (hostname === 'seating.labdepotinc.com') {
  app = <LabDepot />;
} else if (hostname === 'taylor.ecomseating.com') {
  app = <Taylor />;
} else if (hostname === 'qaline.ecomseating.com') {
  app = <QALine />;
} else if (hostname === 'seating.novatech-usa.com') {
  app = <Nova />;
} else if (hostname === 'nowak.ecomseating.com') {
  app = <Nowak />;
} else if (hostname === 'nelson.ecomseating.com') {
  app = <Nelson />;
} else if (hostname === 'chairs.nelsonjameson.com') {
  app = <Nelson />;
} else if (hostname === 'bsi.ecomseating.com') {
  app = <BSI />;
} else if (hostname === 'seating.blueridgeergonomics.com') {
  app = <BlueRidge />;
} else if (hostname === 'seating.daiscientific.com') {
  app = <DAI />;
} else if (hostname === 'daiggerlabchairs.com') {
  app = <Daigger />;
} else if (hostname === 'dsi.ecomseating.com') {
  app = <DSI />;
} else if (hostname === 'define.ecomseating.com') {
  app = <Define />;
} else if (hostname === 'chairs.andwinsci.com') {
  app = <Andwin />;
} else if (hostname === 'seating.omnilabsolutions.com') {
  app = <Omnilab />;
} else if (hostname === '1prolineseating.com') {
  app = <Proline />;
} else if (hostname === 'seating.geneseesci.com') {
  app = <Genesee />;
} else if (hostname === 'gsschair.govsci.com') {
  app = <GSS />;
} else if (hostname === 'catalog.scottlaboratorysolutions.com' || hostname === 'seating.scottlaboratorysolutions.com') {
  app = <Scott />;
} else if (hostname === 'spectrum.ecomseating.com') {
  app = <Spectrum />;
} else if (hostname === 'spectrumrx.ecomseating.com') {
  app = <SpectrumRX />;
} else if (hostname === 'seating.stellarscientific.com') {
  app = <Stellar />;
} else if (hostname === 'seating.srsonline.com') {
  app = <SRS />;
} else if (hostname === 'chairs.scisale.com') {
  app = <SCI />;
} else if (hostname === 'thermal.ecomseating.com') {
  app = <Thermal />;
} else if (hostname === 'thomaschair.com') {
  app = <Thomas />;
} else if (hostname === 'thomasscichair.com') {
  app = <Thomas />;
} else if (hostname === 'www.thomasscichair.com') {
  app = <Thomas />;
} else if (hostname === 'www.thomaschair.com') {
  app = <Thomas />;
} else if (hostname === 'seating.kewaunee.com') {
  app = <Kewaunee />;
} else if (hostname === 'seating.instocklabs.com') {
  app = <LBI />;
} else if (hostname === 'catalog.locinc.com') {
  app = <LOC />;
} else if (hostname === 'labdepot.ecomseating.com') {
  app = <LabDepot />;
} else if (hostname === 'catalog.midlandsci.com') {
  app = <Standard partner="midland" />;
} else if (hostname === 'catalog.officeplanners.com') {
  app = <OfficePlanners />;
} else if (hostname === 'chairs.onepointesolutions.com') {
  app = <OnePointe />;
} else if (hostname === 'test.ecomseating.com') {
  app = <EcomIframe />;
} else if (hostname === 'catalog.ecomseating.com') {
  app = <EcomSubdomain />;
} else if (hostname === 'ecomseating.com') {
  app = <Ecom />;
} else if (hostname === 'www.ecomseating.com') {
  app = <Ecom />;
} else if (hostname === 'seating.kloeppel.com') {
  app = <Kloeppel />;
} else if (hostname === 'labfurniture.mglabsolutions.com') {
  app = <MGLab />;
} else if (hostname === 'catalog.nycominc.com') {
  app = <Nycom />;
} else if (hostname === 'seating.nycominc.com') {
  app = <Nycom />;
} else if (hostname === 'farnell.ecomseating.com') {
  app = <Farnell />;
} else if (hostname === 'chairs.labproservices.com') {
  app = <LPS />;
} else if (hostname === 'seating.phillocraft.com') {
  app = <Phillocraft />;
} else if (hostname === 'seating.biologix.store') {
  app = <Biologix />;
} else if (hostname === 'seating.emeraldscientific.com') {
  app = <Emerald />;
} else if (hostname === 'seating.labproinc.com') {
  app = <Labpro />;
} else if (hostname === 'seating.iciscientific.com') {
  app = <ICI />;
} else if (hostname === 'chairs.aresscientific.com') {
  app = <Standard partner="ares" />;
} else if (hostname === 'seating.medsupplypartners.com') {
  app = <Standard partner="medsupply" />;
} else if (hostname === 'seating.labbuild.com') {
  app = <Standard partner="labbuild" />;
} else if (hostname === 'chairs.scilogex.com') {
  app = <Standard partner="scilogex" />;
} else if (hostname === 'seating.canadianscientific.ca') {
  app = <Standard partner="csi" />;
} else if (hostname === 'chairs.krackeler.com') {
  app = <Standard partner="krackeler" />;
} else if (hostname === 'seating.symbiote.com') {
  app = <Standard partner="symbiote" />;
} else if (
  hostname === 'labchairs.azerscientific.com'
) {
  app = <Azer />;
} else if (
  hostname === 'seating.haldemanhomme.com'
) {
  app = <H2I />;
} else if (
  hostname === 'fisher.localhost'
  || hostname === 'fisher.ecomseating.com'
  || hostname === 'fisherchair.com'
  || hostname === 'www.fisherchair.com'
  || hostname === 'fisherchairs.com'
  || hostname === 'www.fisherchairs.com'
) {
  app = <Fisher />;
} else if (
  hostname === 'fishered.localhost'
  || hostname === 'fisheredchair.com'
  || hostname === 'www.fisheredchair.com'
  || hostname === 'catalog.fisherchair.com'
  || hostname === 'fisheredchairs.com'
  || hostname === 'www.fisheredchairs.com'
  || hostname === 'education.fisherchair.com'
) {
  app = <FisherEd />;
} else if (ancestorOrigins[0] === 'https://technimark-inc.com') {
  app = <Technimark />;
}

function AppWrapper() {
  return <React.Suspense fallback={<Loading />}>{app}</React.Suspense>;
}

ReactDOM.render(<StrictMode><AppWrapper /></StrictMode>, document.getElementById('root'));
